/* ChatRoom.css */
@keyframes blink {
    0% {
      opacity: 1;
    }
    33% {
      opacity: 0.3;
    }
    66% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
  
  .loading-dots span {
    display: inline-block;
    margin-left: 2px;
    font-size: 24px;
    line-height: 1;
  }
  
  .loading-dots span:nth-child(1) {
    animation: blink 1.4s infinite both;
  }
  
  .loading-dots span:nth-child(2) {
    animation: blink 1.4s infinite both 0.2s;
  }
  
  .loading-dots span:nth-child(3) {
    animation: blink 1.4s infinite both 0.4s;
  }
  