/* src/pages/SketchResult.css */
.sketch-result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #F7F3FD;
    min-height: 100vh;
    padding: 20px;
  }
  
  .sketch-result-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .sketch-result-header img {
    max-width: 100px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .characters-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .character-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
  }
  
  .character-card img {
    max-height: 300px;
    width: fit-content;
    max-width: 230px;
    border-radius: 8px;
  }

  .thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  
  .thumbnail {
    width: fit-content; /* 썸네일 크기 조정 */
    height: 60px;
    margin: 5px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .character-card .placeholder {
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 240px;
    border-radius: 8px;
    padding: 50px 0;
    color: #888;
  }

  .backgrounds-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .background-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
    justify-content: space-between;
  }
  
  .background-card img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .background-card .placeholder {
    background-color: #eee;
    border-radius: 8px;
    padding: 50px 0;
    color: #888;
  }
  
/* 로딩 바와 텍스트를 담는 컨테이너 */
.loading-bar-container {
  height: 30px;
  position: relative;
  width: 80%;
  margin-top: 60px;
}

/* 텍스트 스타일 */
.loading-bar-text {
  display: flex;
  justify-content: center;  /* 가로 중앙 정렬 */
  align-items: center;      /* 세로 중앙 정렬 */
  position: absolute;
  height: 100%;
  width: 100%;
  color: white; /* 텍스트 색상 */
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
}


/* 로딩 바 스타일 */
.loading-bar {
  height: 30px;
  background-color: #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.loading-bar::after {
  content: "";
  display: block;
  height: 30px;
  background-color: #9D3FE7;
  width: 100%;
  animation: loading 3s linear infinite;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

  