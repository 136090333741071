@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Onest:wght@700&display=swap");
@font-face {
  font-family: "Pretendard";
  src: url("/public/Pretendard-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Pretendard";
  src: url("/public/Pretendard-Medium.otf");
  font-weight: 500;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

/* 예시 반응형 디자인 추가 */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 반응형 디자인 추가 */
@media (max-width: 1200px) {
  body {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 10px;
  }
}
